import React, {useEffect} from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './Context/AuthContext'
import { InboxProvider } from './Context/InboxContext'
// Components
import Landing from './Components/PublicUI/Landing/Landing';
import OurStory from './Components/PublicUI/Landing/OurStory';
import Login from './Components/PublicUI/Login';
import ResetPassword from './Components/PublicUI/ResetPassword'
import Acts from './Components/ArtistUI/Acts';
import Find from './Components/PublicUI/Find';
import PublicUI from './Components/PublicUI/PublicUI';
import ArtistList from './Components/ArtistUI/Admin/ArtistList';
import RequestList from './Components/ArtistUI/RequestList';
import AllRequestList from './Components/ArtistUI/Admin/RequestList';
import Request from './Components/ArtistUI/Request';
import ArtistInbox from './Components/ArtistUI/Inbox';
import SupportInbox from './Components/ArtistUI/Admin/SupportInbox';
import PublicInbox from './Components/PublicUI/PublicInbox';
import AllInbox from './Components/ArtistUI/Admin/AllInbox';
import Chat from './Components/ArtistUI/Chat';
import SupportChat from './Components/ArtistUI/Admin/SupportChat';
import PublicChat from './Components/PublicUI/PublicChat';
import ClientChat from './Components/ClientUI/ClientChat';
import AllChat from './Components/ArtistUI/Admin/AllChat';
import FindArtists from './Components/PublicUI/Artists/FindArtists';
import ArtistProfile from './Components/PublicUI/Artists/ArtistProfile';
import RequireAuth from './Components/Auth/RequireAuth'
import MusicianUI from './Components/ArtistUI/MusicianUI'
import Calendar from './Components/ArtistUI/Calendar'
import Quote from './Components/ClientUI/Quote';
import ActManager from './Components/ArtistUI/ActManager';
import RequireAdminAuth from './Components/Auth/RequireAdminAuth';
import TempLanding from './Components/PublicUI/Landing/TempLanding';
import ActList from './Components/ArtistUI/Admin/ActList';
import ClientUI from './Components/ClientUI/ClientUI'
import Wedding from './Components/ClientUI/Wedding';
import ClientInbox from './Components/ClientUI/ClientInbox';
import Blogs from './Components/PublicUI/Blog/Blogs';
import Blog from './Components/PublicUI/Blog/Blog';
import BookingList from './Components/ArtistUI/Admin/BookingList';
import SystemReport from './Components/ArtistUI/Admin/SystemReport';
import ScrollToTop from './Components/ScrollToTop';
import Hotjar from '@hotjar/browser';
import SuccessPage from './Components/PublicUI/SuccessPage';
import ReactGA from "react-ga4";
import PageNotFound from './Components/PublicUI/PageNotFound';
import SuccessfulPaymentPage from './Components/PublicUI/SuccessfulPaymentPage';
//Terms
import ArtitsTerms from './Components/PublicUI/Terms/ArtistTerms';
import CommunityGuidelines from './Components/PublicUI/Terms/CommunityGuidelines';
import ClientTerms from './Components/PublicUI/Terms/ClientTerms';
import ContactSupport from './Components/ContactSupport';
import ArtistSignup from './Components/PublicUI/ArtistSignup';
import Wallet from './Components/ArtistUI/Wallet';
import AdminDashboard from './Components/ArtistUI/Admin/AdminDashboard';
import Invoice from './Components/ArtistUI/Invoice';
import Quotes from './Components/ClientUI/Quotes';
import ArtistDashboard from './Components/ArtistUI/ArtistDashboard';
import ArtistWeddings from './Components/ArtistUI/ArtistWeddings';
import Competition from './Components/PublicUI/Competition';
import CompetitionTerms from './Components/PublicUI/Terms/CompetitionTerms';


function App() {

  //Hotjar for user heatmaps and Google Analytics
  const googleTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
  const siteId = process.env.REACT_APP_HOTJAR;
  const hotjarVersion = 6;

  let url = window.location.href;

  useEffect(() => {
    if (!url.includes("localhost")) {
      ReactGA.initialize(googleTrackingId);
      Hotjar.init(siteId, hotjarVersion);
    }
  }, [])


  return (
    <AuthProvider>
    <InboxProvider>
    <ToastContainer position='top-center' />

    <BrowserRouter>
      <ScrollToTop />
      <ContactSupport>
      <Routes>

         {/*Public routes */}
        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/client-terms' element={<ClientTerms />} />
        <Route path='/artist-terms' element={<ArtitsTerms />} />
        <Route path='/community-guidelines' element={<CommunityGuidelines />} />

        {url.includes("test.firstdancemusic") || url.includes("fdm-test.netlify") ?
          <Route element={<RequireAdminAuth />} >
            <Route path='/' element={<PublicUI />}>
              <Route index element={<Landing />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/blogs/:id' element={<Blog />} />
              <Route path='/landing' element={<TempLanding />} />
              <Route path='/our-story' element={<OurStory />} />
              <Route path='/find' element={<Find />} />
              <Route path='/success' element={<SuccessPage />} />
              <Route path='/payment-success' element={<SuccessfulPaymentPage />} />
              <Route path='/find/artists' element={<FindArtists />} />
              <Route path='/find/artists/:id' element={<ArtistProfile />} />
              <Route path='/inbox/:userId' element={<PublicInbox />} />
              <Route path='/inbox/:userId/:id' element={<PublicChat />} />
              <Route path='/artist-signup' element={<ArtistSignup />} />
              <Route path='/competition' element={<Competition />} />
            </Route>
          </Route>
          :
            <Route path='/' element={<PublicUI />}>
              <Route index element={<Landing />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/blogs/:id' element={<Blog />} />
              <Route path='/landing' element={<TempLanding />} />
              <Route path='/our-story' element={<OurStory />} />
              <Route path='/find' element={<Find />} />
              <Route path='/success' element={<SuccessPage />} />
              <Route path='/payment-success' element={<SuccessfulPaymentPage />} />
              <Route path='/find/artists' element={<FindArtists />} />
              <Route path='/find/artists/:id' element={<ArtistProfile />} />
              <Route path='/inbox/:userId' element={<PublicInbox />} />
              <Route path='/inbox/:userId/:id' element={<PublicChat />} />
              <Route path='/artist-signup' element={<ArtistSignup />} />
              <Route path='/competition' element={<Competition />} />
              <Route path='/competition-terms' element={<CompetitionTerms />} />
            </Route>
        }

        {/* client Auth routes */}
        <Route element={<RequireAuth authRole="client" />}>
          <Route path='/clients' element={<ClientUI />}>
            <Route index element={<Wedding />} />
            <Route path='/clients/wedding' element={<Wedding />} />
            <Route path='/clients/inbox' element={<ClientInbox />} >
              <Route path=':id' element={<ClientChat />} />
            </Route>
            <Route path='/clients/quotes' element={<Quotes />} />
            <Route path='/clients/quote/:id' element={<Quote />} />
          </Route>
        </Route>

        {/*artists Auth routes*/}
        <Route path="/artists" element={<RequireAuth authRole="artist" />}>
          <Route path='/artists' element={<MusicianUI />}>
            <Route index element={<ArtistDashboard />} />
            <Route path='/artists/acts' element={<Acts />} />
            <Route path='/artists/acts/:id' element={<ActManager />} />
            <Route element={<RequireAdminAuth />} >
              <Route path='/artists/admin' element={<AdminDashboard />}>
                <Route path='list' element={<ArtistList />} />
                <Route path='act-list' element={<ActList />} />
                <Route path='bookings' element={<BookingList />} />
                <Route path='all-requests' element={<AllRequestList />} />
                <Route path='support-inbox' element={<SupportInbox />} />
                <Route path='support-inbox/:id' element={<SupportChat />} />
                <Route path='all-inbox' element={<AllInbox />} />
                <Route path='all-inbox/:id' element={<AllChat />} />
                <Route path='system' element={<SystemReport />} />
              </Route>
            </Route>
            <Route path='/artists/weddings' element={<ArtistWeddings />} />
            <Route path='/artists/requests/:id' element={<Request />} />
            <Route path='/artists/inbox' element={<ArtistInbox />}>
              <Route path=':id' element={<Chat />} />
            </Route>
            <Route path='/artists/wallet' element={<Wallet />} />
            <Route path='/artists/invoices/:id' element={<Invoice />} />
          </Route>
        </Route>
        {/* Wildcard route if not found */}
        <Route path='*' element={<PageNotFound/>} />
      </Routes>
      </ContactSupport>
    </BrowserRouter>
  </InboxProvider>
  </AuthProvider>
  );
}

export default App;
