import React, { useState, useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { useAuth } from '../../../Context/AuthContext';
import { toast } from 'react-toastify'
import Switch from '@mui/material/Switch';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../../Firebase';

export default function ArtistList() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [artists, setArtists] = useState([])


  useEffect(() => {
    getArtists()
  }, [])

  const getArtists = () => {
    axios.get(`${process.env.REACT_APP_API}/users/get-artists`)
    .then((res)=>{
      console.log(res.data)
      setArtists(res.data)
    })
    .catch((e)=>console.log(e))
  }

  const handleSave = () => {
    if (!firstName || !lastName || !email ) {
      toast.error("Please add name and email")
    } else {
      axios.post(
        `${process.env.REACT_APP_API}/users/create-new-artist`,
        {
          firstName,
          lastName,
          email,
          number,
          img:"https://caretestresources.s3.eu-west-2.amazonaws.com/avatar.png"
        })
      .then(() => {
        // send email to reset password
        sendPasswordResetEmail(auth, email)
        .then(() => {
          toast.success("Successfully added")
          handleClose()
          getArtists()
          setFirstName(""); setLastName(""); setEmail(""); setNumber("");
        })
        .catch((error) => {
          console.log("Error issuing password reset email", error)
        });
      })
      .catch((error) => {
        console.log("Error creating artist", error);
        // catch sanitised messages
        toast.error(error.response?.data?.message || "Error creating artist")
      })
    }
  }

  const handleAdmin = (checked, uid) => {
    console.log(checked)
    axios.post(`${process.env.REACT_APP_API}/users/set-admin`, {uid, checked})
    .then((res)=>{
      toast.success(res.data)
      getArtists()
    })
    .catch((error) => console.log(error))
  }

  const handleDelete = (uid, email) => {
    const confirmed = window.confirm(`Are you sure you want to delete the artist with email ${email}?`);
    if (confirmed) {
          axios.post(`${process.env.REACT_APP_API}/users/delete-artist`, {artistId: uid})
      .then((res)=>{
        toast.success(res.data)
        getArtists()
      })
      .catch((error) => console.log(error))
    }
  }

  return (
    <div style={{textAlign:'center', backgroundColor:'#f8f9fb', height: '100vh', overflowX:'auto'}}>
         <Button sx={{backgroundColor: '#ecbdba', color:'white', margin: '2rem auto'}} variant='contained' onClick={handleOpen}>Add New Artist</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <TextField sx={{my:1}} fullWidth label='First Name' value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth label='Last Name' value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth label='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth label='Number (inc +44)' value={number} onChange={(e)=>setNumber(e.target.value)}/>
          <Button variant='contained' onClick={handleSave} sx={{backgroundColor:'#ecbdba'}}>Save</Button>
        </Box>
      </Modal>

      <table id='artist-list-table' style={{width: 800, maxWidth:'90vw', margin: '1rem auto'}}>
        <thead>
          <tr style={{border:'1px solid grey'}}>
            <td></td>
            <td>Name</td>
            <td>Email</td>
            <td>Number</td>
            <td>Admin</td>
            <td>Delete</td>
          </tr>
        </thead>
        <tbody>
          {artists.map((artist, i) => {
            return <tr key={i}>
              <td>
                <img
                  style={{height: 40, borderRadius:'50%', objectFit:'cover', objectPosition:'center'}}
                  src={artist.img}
                  alt={artist.firstName}
                />
              </td>
              <td>{artist.firstName} {artist.lastName}</td>
              <td>{artist.email}</td>
              <td>{artist.number}</td>
              <td>
                <Switch
                  checked={artist.admin}
                  color="success"
                  onChange={(e) => {handleAdmin(e.target.checked, artist._id)}}
                />
              </td>
              <td> {/* might want to undelete in future */}
                <Switch
                  checked={false}
                  color="success"
                  onChange={() => {handleDelete(artist._id, artist.email)}}
                />
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};